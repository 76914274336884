*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Agenda, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.max-w-prose {
  max-width: 65ch;
}

.grow {
  flex-grow: 1;
}

.list-inside {
  list-style-position: inside;
}

.list-none {
  list-style-type: none;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-center {
  align-content: center;
  justify-content: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.border-2 {
  border-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-indigo-base {
  --tw-border-opacity: 1;
  border-color: rgb(63 50 93 / var(--tw-border-opacity) );
}

.bg-gray-light {
  --tw-bg-opacity: 1;
  background-color: rgb(216 219 226 / var(--tw-bg-opacity) );
}

.bg-indigo-base {
  --tw-bg-opacity: 1;
  background-color: rgb(63 50 93 / var(--tw-bg-opacity) );
}

.bg-gray-base {
  --tw-bg-opacity: 1;
  background-color: rgb(141 153 174 / var(--tw-bg-opacity) );
}

.bg-indigo-light {
  --tw-bg-opacity: 1;
  background-color: rgb(66 81 124 / var(--tw-bg-opacity) );
}

.bg-bluegray-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(43 45 59 / var(--tw-bg-opacity) );
}

.p-3 {
  padding: .75rem;
}

.p-6 {
  padding: 1.5rem;
}

.text-center {
  text-align: center;
}

.font-sans {
  font-family: Agenda, sans-serif;
}

.font-flair {
  font-family: Abel;
}

.font-serif {
  font-family: EB Garamond, Georgia, Cambria, serif;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

.italic {
  font-style: italic;
}

.leading-relaxed {
  line-height: 1.625;
}

.text-bluegray-base {
  --tw-text-opacity: 1;
  color: rgb(43 45 66 / var(--tw-text-opacity) );
}

.text-indigo-light {
  --tw-text-opacity: 1;
  color: rgb(66 81 124 / var(--tw-text-opacity) );
}

.text-bluegray-dark {
  --tw-text-opacity: 1;
  color: rgb(43 45 59 / var(--tw-text-opacity) );
}

.text-yellow-light {
  --tw-text-opacity: 1;
  color: rgb(255 255 199 / var(--tw-text-opacity) );
}

.text-yellow-base {
  --tw-text-opacity: 1;
  color: rgb(255 224 102 / var(--tw-text-opacity) );
}

.text-gray-light {
  --tw-text-opacity: 1;
  color: rgb(216 219 226 / var(--tw-text-opacity) );
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(254 95 85 / var(--tw-text-opacity) );
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@font-face {
  font-family: Agenda;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("agenda-bold.63b2a5aa.woff2") format("woff2"), url("agenda-bold.01db7789.woff") format("woff"), url("agenda-bold.798d4662.ttf") format("truetype");
}

@font-face {
  font-family: Agenda;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("agenda-medium-webfont.2e2f15c2.woff2") format("woff2"), url("agenda-medium-webfont.53b5d4db.woff") format("woff"), url("agenda-medium.ee2f6225.ttf") format("truetype");
}

@font-face {
  font-family: Agenda;
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("agenda-medium-italic-webfont.7a97fe79.woff2") format("woff2"), url("agenda-medium-italic-webfont.f5f428fa.woff") format("woff"), url("agenda-medium-italic.867ffd1e.ttf") format("truetype");
}

@font-face {
  font-family: Agenda;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("agenda-light-webfont.a40b162a.woff2") format("woff2"), url("agenda-light-webfont.cdf76ce0.woff") format("woff"), url("agenda-light.3235bbfc.ttf") format("truetype");
}

@font-face {
  font-family: Agenda;
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("agenda-light-italic-webfont.47bd93e1.woff2") format("woff2"), url("agenda-light-italic-webfont.902f83b8.woff") format("woff"), url("agenda-light-italic.be7d2f56.ttf") format("truetype");
}

@font-face {
  font-family: Abel;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("abel-regular-webfont.80e1468b.woff2") format("woff2"), url("abel-regular-webfont.e52d2ae2.woff") format("woff"), url("Abel-Regular.38622680.ttf") format("truetype");
}

@media (min-width: 768px) {
  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.social-link > a {
  border-radius: .5rem;
  display: block;
}

.social-link > a:hover {
  outline-offset: 1px;
  outline: 2px solid #ffe066;
}

.social-link > a > picture {
  width: 4rem;
}

@media (min-width: 768px) {
  .social-link > a > picture {
    width: 3.5rem;
  }
}

.timeline > li {
  padding: .75rem;
}

.timeline > li:not(:last-child) {
  border-bottom-width: 2px;
}

.timeline > li:not(:last-child) {
  border-style: solid;
}

.timeline > li:not(:last-child) {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 199 / var(--tw-border-opacity) );
}

body section:not(:first-child) {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

/*# sourceMappingURL=index.bb78b95f.css.map */
