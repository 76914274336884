.social-link {
  & > a {
    @apply block rounded-lg hover:outline hover:outline-2 hover:outline-offset-1 hover:outline-yellow-base;
  }

  & > a > picture {
    @apply w-16 md:w-14;
  }
}

.timeline {
  & > li {
    @apply p-3;

    &:not(:last-child) {
      @apply border-b-2 border-yellow-light border-solid;
    }
  }
}